/**
 * Timetastic
 * Pricing button component
 */
import React from "react"

// Helpers
import { trackCta } from "src/helpers"
import { doSignupClick } from "../../helpers/analytics"

// Styles
//  import "./freeTrial.scss"

// JSX
const PricingButton = ({
  buttonCopy,
  buttonSubCopy1,
  trackCtaLabel,
  startWithPro,
}) => {
  return (
    <a
      className="c-button c-button--primary c-button--xxl"
      href="#"
      onClick={() => {
        doSignupClick(startWithPro)
        trackCta(trackCtaLabel)
      }}
    >
      {buttonCopy}
      <span className="c-button--sub-copy">{buttonSubCopy1}</span>
    </a>
  )
}

export default PricingButton
