/**
 * Timetastic
 * Pricing Quotes component
 */
import React from "react"
import PropTypes from "prop-types"

// Components
import PricingQuote from "./pricingQuote"

// Styles
import "./pricingQuotes.scss"

const PricingQuotes = ({ quotes }) => {
  return (
    <section className="c-section c-pricing-quotes">
      <div className="u-inner u-inner--l">
        {quotes &&
          quotes.map((quote, index) => (
            <PricingQuote
              avatar={quote.avatar}
              company={quote.company}
              key={`quote-${index}`}
              name={quote.name}
              quote={quote.quote}
            />
          ))}
      </div>
    </section>
  )
}

PricingQuotes.propTypes = {
  quotes: PropTypes.arrayOf(
    PropTypes.shape({
      avatar: PropTypes.string.isRequired,
      company: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      quote: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default PricingQuotes